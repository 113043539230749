import { omit, camelCase } from 'lodash';

import {
    categoryTypes,
    SEARCH_CATEGORY_CHANGE_TYPE,
    SEARCH_CATEGORY_CHANGE_KEYWORD,
    SEARCH_CATEGORY_DATA,
    SEARCH_CATEGORY_DATA_LOADING,
    SEARCH_CATEGORY_DATA_ERROR,
    SEARCH_CATEGORY_TOGGLE_SELECTION,
    SEARCH_CATEGORY_RESET_SELECTION,
} from "../constants";
import { generateReducer } from "../../common/helpers";

const emptySelections = {
    selectedCustomCategory: null,
    selectedSubCategories: [],
    selectedCategories: [],
    selectedPrices: [],
    selectedSizes: [],
};

const initialState = {
    categoryTypes,
    showInput: false,
    inputKeyword: '',
    selectedType: categoryTypes[0],

    prices: ['Value', 'Mid', 'Premium', 'Super'],
    customCategories: [],
    subCategories: {},
    categories: [],
    sizes: [],

    ...emptySelections,

    isLoading: [],
    errors: {},
};

const changeKeyword = (state, keyword) => ({ ...state, keyword });
const changeType = (state, selectedType) => ({ ...state, selectedType });

const setData = (state, {type, data}) => {
    const newState = {
        ...state,
        errors: omit(state.errors, [type]),
        isLoading: state.isLoading.filter(l => l !== type),
    };

    if (type === 'subCategories') {
        newState.subCategories[data[0].category] = data.map(({sub_category}) => sub_category);
    } else {
        newState[type] = data;
    }

    return newState;
};
const setLoading = (state, type) => ({ ...state, isLoading: [...state.isLoading, type] });
const setLoadingError = (state, {type, error}) => ({ ...state, errors: {...state.errors, [type]: error} });
const resetSelection = (state) => ({ ...state, ...emptySelections, });
const toggleSelection = (state, {type, value}) => {
    const newState = { ...state, selectedType: categoryTypes[0] },
        key = camelCase(`selected ${type}`);

    if (type === 'customCategory') {
        return { ...state, ...emptySelections, [key]: value, selectedType: categoryTypes[1] };
    }

    newState.selectedCustomCategory = null;
    if (state[key].indexOf(value) >= 0) {
        newState[key] = state[key].filter(val => val !== value);
    } else {
        newState[key] = [...state[key], value];
    }

    if (newState.selectedCategories.length > 1) {
        newState.selectedSubCategories = [];
    }

    if (newState.selectedSubCategories.length > 1) {
        newState.selectedSizes = [];
    }

    if (newState.selectedSizes.length > 0) {
        newState.selectedPrices = [];
    }

    return newState;
};

const reducers = {
    [SEARCH_CATEGORY_DATA]: setData,
    [SEARCH_CATEGORY_DATA_LOADING]: setLoading,
    [SEARCH_CATEGORY_DATA_ERROR]: setLoadingError,
    [SEARCH_CATEGORY_RESET_SELECTION]: resetSelection,
    [SEARCH_CATEGORY_TOGGLE_SELECTION]: toggleSelection,

    [SEARCH_CATEGORY_CHANGE_TYPE]: changeType,
    [SEARCH_CATEGORY_CHANGE_KEYWORD]: changeKeyword,
};

export default generateReducer(reducers, initialState);