import {
    SEARCH_BOX_TOGGLE, 
    SEARCH_BOX_SAVING, 
    SEARCH_BOX_SAVED,
} from "../constants";
import { generateReducer } from "../../common/helpers";

const initialState = {
    showBox: true,
    isSaving: false,
};

const toggleBox = (state) => ({ ...state, showBox: !state.showBox });
const setSaving = (state) => ({ ...state, isSaving: true });
const setSaved = (state) => ({ ...state, isSaving: false });

const reducers = {
    [SEARCH_BOX_TOGGLE]: toggleBox,
    [SEARCH_BOX_SAVING]: setSaving,
    [SEARCH_BOX_SAVED]: setSaved,
};

export default generateReducer(reducers, initialState);