import { get, debounce } from 'lodash';

import { 
    SETTING_CHANGE_REQUEST, 
    SETTING_CHANGE_SUCCESS, 
    SETTING_CHANGE_ERROR, 

    SETTING_TOGGLE_COMPARE_PERIOD,
    SETTING_CHANGE_COMPARE_PERIOD,
    SETTING_CHANGE_SALES_PERIOD,
    SETTING_GEO_OPTIONS,
    SETTING_GEO_SEARCH_QUERY,
    SETTING_GEO_SEARCH_LOADING,
    SETTING_GEO_SEARCH_RESULTS,
} from './constants';
import { makePrivateApiCall } from '../common/api';
import { changeSideNavView } from '../common/actions';
import { parseDateRange } from './helpers';

export const changeSetting = (setting={}) => (dispatch, getState) => {
    dispatch({
        type: SETTING_CHANGE_REQUEST,
        payload: setting
    });

    return makePrivateApiCall({
        url: '/user/setting',
        method: 'put', data: setting,
    }).then((res) => {
        if (res.data && res.data.success) {
            dispatch({
                type: SETTING_CHANGE_SUCCESS,
                payload: res.data.settings
            });
        }
    }).catch(err => {
        dispatch({
            type: SETTING_CHANGE_ERROR,
            payload: {
                error: get(err, 'response.data.message', null) || err.message,
                setting
            }
        });
    });
};

export const loadDefaultGeoOptions = () => (dispatch) => {
    makePrivateApiCall({
        url: '/user/setting/geo/default-options',
    }).then((res) => {
        if (res.data) {
            dispatch({
                type: SETTING_GEO_OPTIONS,
                payload: res.data
            });
        }
    }).catch(err => console.log(err));
};

export const toggleSettingComparePeriod = () => ({
    type: SETTING_TOGGLE_COMPARE_PERIOD,
});

export const changeSettingComparePeriod = (period) => ({
    type: SETTING_CHANGE_COMPARE_PERIOD,
    payload: period,
});

export const changeSettingSalesPeriod = (period) => ({
    type: SETTING_CHANGE_SALES_PERIOD,
    payload: period,
});

export const changeSettingGeoSearchQuery = (keyword) => (dispatch) => {
    if (keyword.length > 2) {
        searchSettingGeo(keyword, dispatch);
    }

    dispatch({
        type: SETTING_GEO_SEARCH_QUERY,
        payload: keyword,
    });
};

export const searchSettingGeo = debounce((keyword, dispatch) => {
    dispatch({
        type: SETTING_GEO_SEARCH_LOADING,
    });

    return makePrivateApiCall({
        url: '/user/setting/geo/options',
        params: { keyword },
    }).then((res) => {
        if (res.data) {
            dispatch({
                type: SETTING_GEO_SEARCH_RESULTS,
                payload: res.data
            });
        }
    }).catch(err => console.log(err));
}, 300);

export const saveSettingCustomDate = () => (dispatch, getState) => {
    let { setting } = getState(),
        [sales_period_start, sales_period_end] = parseDateRange(setting.salesPeriod),
        [compare_period_start, compare_period_end] = parseDateRange(setting.comparePeriod),
        newSettings = {
            sales_period_type: 'custom',
            sales_period_start, sales_period_end,
        };
    
    if (compare_period_start !== null) {
        newSettings = { ...newSettings, compare_period_start, compare_period_end };
    }

    dispatch(changeSideNavView(null));
    return dispatch(changeSetting(newSettings));
};