import React from 'react';
import { useSelector, useDispatch } from "react-redux";
import { startCase, isEmpty, flatten, pickBy, last } from 'lodash';
import { Header, Segment, Input, Icon, List } from 'semantic-ui-react';

import { changeSideNavView } from '../../common/actions';
import { changeSettingGeoSearchQuery, changeSetting, } from '../actions';

const SettingContainerGeo = () => {
    const {
        geoOptions,
        geoSearchQuery,
        geoSearchLoading,
        geoSearchResults,
    } = useSelector(({ setting }) => setting);

    const hasSearchResults = !isEmpty(Object.keys(geoSearchResults)),
        options = (geoSearchQuery.length > 2 && hasSearchResults) ? geoSearchResults : geoOptions,
        selectableOptions = pickBy(options, (values) => !isEmpty(values)),
        noResults = isEmpty(flatten(Object.values(selectableOptions))); 

    const dispatch = useDispatch();
    const handleQuery = (e, {value}) => dispatch(changeSettingGeoSearchQuery(value));

    const handleSelection = (geo_type, geo_name) => {
        dispatch(changeSetting({ geo_name, geo_type }));
        dispatch(changeSideNavView(null));
    };

    return (
        <div>
            <Segment vertical>
                <div className="sm-10">
                    <Header>
                        Select An Area
                    </Header>
                </div>
            </Segment>

            <Segment vertical>
                <div className="sp-10">
                    <Input
                        fluid
                        transparent
                        value={geoSearchQuery}
                        onChange={handleQuery}
                        placeholder='Quick search...'
                        icon={<Icon
                            name={geoSearchQuery.length > 0 ? 'close' : 'search'}
                            onClick={() => handleQuery(null, { value: '' })}
                            link={geoSearchQuery.length > 0}
                        />}
                    />
                </div>
            </Segment>

            <Segment vertical loading={geoSearchLoading}>
                {geoSearchQuery.length > 2 && noResults && (
                    <div className="sp-5">
                        Sorry, No location found for your search <b>"{geoSearchQuery}"</b>
                    </div>
                )}

                {Object.keys(selectableOptions).map(key => (
                    <div key={`geo_option_header_${key}`} className="tp-20">
                        <Header as="h5">
                            <span className="sp-10">{ startCase(last(key.split('_'))) }</span>
                        </Header>
                        <List link animated>
                            {selectableOptions[key].map(geo => (
                                <List.Item 
                                    as="a"
                                    key={`geo_option_${key}_${geo}`}
                                    onClick={(e) => {
                                        e.preventDefault(); 
                                        handleSelection(key, geo);
                                    }}
                                >
                                    <span className="sp-10">{startCase(geo)}</span>
                                </List.Item>
                            ))}
                        </List>
                    </div>
                ))}
            </Segment>
        </div>
    );
};

export default SettingContainerGeo;