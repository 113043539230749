import { 
    ESTABLISHMENT_REPORT_GRID_LOADING, 
    ESTABLISHMENT_REPORT_GRID_SUCCESS, 
    ESTABLISHMENT_REPORT_GRID_ERROR,
    ESTABLISHMENT_REPORT_GRID_SYNC_COLUMNS,
    ESTABLISHMENT_REPORT_GRID_STATE_CHANGE,
    ESTABLISHMENT_REPORT_GRID_TOGGLE
} from "./constants";
import { generateReducer } from "../common/helpers";
import { DefaultSettings } from "../setting/constants";
import { getEstablishmentReportGridColumns } from "./columns";

const columnDefs = getEstablishmentReportGridColumns(DefaultSettings);
const initialState = {
    isLoading: false,
    error: null,
    show: false,
    data: [],

    pageSize: 10,
    currentPage: 0,

    sortModel: [],
    filterModel: {},

    hiddenColumns: [],
    columnDefs: Object.values(columnDefs),
    shownColumns: Object.keys(columnDefs),

    corporate: false,
    chainGroup: false,
    corporateGroup: false,
};

const setGridLoading = (state) => ({ ...state, isLoading: true });
const setGridError = (state, error) => ({ ...state, isLoading: false, error });
const setGridData = (state, gridData) => ({ ...state, data: gridData, isLoading: false, show: true });
const setGridColumns = (state, {settings, isMarketSearch}) => {
    const columnDefs = getEstablishmentReportGridColumns(settings, state, isMarketSearch);

    return { ...state, columnDefs: Object.values(columnDefs), shownColumns: Object.keys(columnDefs) };
};
const changeGridState = (state, payload) => ({ ...state, ...payload, });
const toggleGrid = (state) => ({ ...state, show: !state.show });

const reducers = {
    [ESTABLISHMENT_REPORT_GRID_LOADING]: setGridLoading,
    [ESTABLISHMENT_REPORT_GRID_SUCCESS]: setGridData,
    [ESTABLISHMENT_REPORT_GRID_ERROR]: setGridError,

    [ESTABLISHMENT_REPORT_GRID_TOGGLE]: toggleGrid,
    [ESTABLISHMENT_REPORT_GRID_STATE_CHANGE]: changeGridState,
    
    [ESTABLISHMENT_REPORT_GRID_SYNC_COLUMNS]: setGridColumns,
};

export default generateReducer(reducers, initialState);