import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Grid, Button, Dropdown, Icon } from 'semantic-ui-react';

import {
    selectSearchProduct,
    changeSearchProductType,
    toggleSearchProductInput,
    changeSearchProductQuery,
} from './actions';

const SearchProductContainer = () => {
    const { 
        productTypes, 
        selectedType, 
        suggestions,
        inputQuery, 
        showInput, 
        isLoading,
        selected,
        error,
    } = useSelector(({ searchProduct }) => searchProduct);

    const dispatch = useDispatch();
    const handleChange = (e, {value}) => dispatch(selectSearchProduct(value));
    const handleSearchChange = (e, {searchQuery}) => dispatch(changeSearchProductQuery(searchQuery, selectedType));

    return (
        <div className="bl-4 blc-blue bp-5">
        <Grid columns={2} verticalAlign='middle' padded className="compact">
            <Grid.Column>
                Your Products
            </Grid.Column>
            <Grid.Column textAlign="right">
                <Dropdown
                    button
                    pointing
                    text={selectedType}
                    className="tiny blue"
                    onChange={(e, { value }) => dispatch(changeSearchProductType(value))}
                    options={productTypes.map(type => ({key: type, value: type, text: type}))}
                />

                <Icon 
                    onClick={() => dispatch(toggleSearchProductInput())}
                    name={ showInput ? "close" : "plus"} 
                    className="clickable"
                    color="blue" 
                    size="large" 
                    />
            </Grid.Column>
        </Grid>

        {showInput && (
            <div className="lp-5 rp-15">
                <Dropdown
                    fluid
                    search
                    selection
                    error={!!error}
                    value={selected}
                    minCharacters={3}
                    autoComplete="off"
                    loading={isLoading}
                    selectOnBlur={false}
                    options={suggestions}
                    onChange={handleChange}
                    searchQuery={inputQuery}
                    onSearchChange={handleSearchChange}
                    icon={inputQuery.length > 2 ? 'dropdown' : null}
                    placeholder={`Start typing ${selectedType} to see suggestions...`}
                    noResultsMessage={inputQuery.length > 2 ? `Sorry, no results found for "${inputQuery}"` : null}
                />
            </div>
        ) }
        </div>
    );
};

export default SearchProductContainer;