import React from 'react';
import { Grid, Header } from 'semantic-ui-react';

import Subheader from '../containers/subheader';
import ProductReportGrid from '../../product-report/grid.container';
import EstablishmentReportGrid from '../../establishment-report/containers/grid';

const CommonHomePage = () => {
    return (
        <Grid className="compact">
            <Grid.Row className="bg-white">
                <Grid.Column>
                    <div className="tp-20 bp-15">
                        <Header>
                            yDrink Swift
                            <Subheader />
                        </Header>
                    </div>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column mobile={16} className="bp-15">
                    <div className="tp-15">
                        <ProductReportGrid />
                    </div>
                    <div className="tp-15">
                        <EstablishmentReportGrid />
                    </div>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

export default CommonHomePage;