import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { Segment, Grid, Button } from 'semantic-ui-react';

import { maximizeSideNav, minimizeSideNav } from '../actions';

const getUserNameInitials = (name) => {
    let frags = name.split(' ');

    if (frags.length < 2) {
        frags = [name[0], name[1]];
    }

    return frags.map(n => n[0].toUpperCase()).join('');
};

const CommonContainerSideNavHeader = () => {
    const dispatch = useDispatch();
    const { user, isMini } = useSelector(state => ({
        user: state.auth.user,
        isMini: state.nav.isMini,
    }));

    return (
        <Segment vertical>
            <Grid columns={2} verticalAlign="middle" padded className="compact">
                <Grid.Column>
                    Welcome To yDrink
                    <br />
                    <Link to="/setting">
                        <b>{user.name}</b>
                    </Link>
                </Grid.Column>
                <Grid.Column textAlign="right">
                    <Button
                        circular
                        size="mini"
                        color="black"
                        icon={isMini ? "close" : "bars"}
                        onClick={() => isMini ? dispatch(maximizeSideNav()) : dispatch(minimizeSideNav())}
                    />
                </Grid.Column>
            </Grid>
        </Segment>
    );
};

export default CommonContainerSideNavHeader;