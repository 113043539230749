import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history';
import storage from 'redux-persist/lib/storage';
import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore, persistCombineReducers } from 'redux-persist';
import { connectRouter, routerMiddleware } from 'connected-react-router';

import auth from "./auth/reducers";
import setting from "./setting/reducers";
import { nav, moduleEnv } from "./common/reducers";
import establishmentReport from "./establishment-report/reducers";

import searchBox from "./search/box/reducers";
import searchAccount from "./search/account/reducers";
import searchProduct from "./search/product/reducers";
import searchCategory from "./search/category/reducers";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const history = createBrowserHistory();
const rootReducer = (history) => persistCombineReducers({
    storage,
    key: 'root',
    whitelist: [
        "nav", "moduleEnv",
    ]
}, {
    router: connectRouter(history),
    auth, nav, moduleEnv, establishmentReport, setting, 
    searchProduct, searchCategory, searchAccount, searchBox,
});

export const store = createStore(
    rootReducer(history),
    composeEnhancers(applyMiddleware(
        thunk,
        routerMiddleware(history), 
    )),
);

export const persistor = persistStore(store);