import React from 'react';
import { Grid, Button } from 'semantic-ui-react';
import { useSelector, useDispatch } from "react-redux";

import { changeSideNavView } from '../../common/actions/nav.js';
import { saveSettingCustomDate } from '../actions.js';

const SettingContainerDateButtons = () => {
    const dispatch = useDispatch();
    const applyDate = () => dispatch(saveSettingCustomDate()),
        cancelDate = () => dispatch(changeSideNavView(null));

    const { salesPeriod, comparePeriod } = useSelector(({ setting }) => setting),
        canApply = (salesPeriod.split('-').length === 2) && (!comparePeriod || salesPeriod.split('-').length === 2);

    return (
        <Grid columns={2} className="super compact">
            <Grid.Column width={4}>
                <Button
                    fluid
                    size="large"
                    color="grey"
                    content="Cancel"
                    onClick={cancelDate}
                    className="straight-border"
                />
            </Grid.Column>
            <Grid.Column width={12}>
                <Button
                    fluid
                    primary
                    size="large"
                    content="Apply"
                    icon="checkmark"
                    onClick={applyDate}
                    disabled={!canApply}
                    className="straight-border"
                />
            </Grid.Column>
        </Grid>
    );
};

export default SettingContainerDateButtons;