import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { Button, Form, Segment, Message } from 'semantic-ui-react';

import { authSigninInputChange, authSignin, authTogglePasswordField } from '../actions';

const SigninForm = ({ 
    isSigningIn, inputs, signinErrors, showPassword,
    authSigninInputChange, authSignin, authTogglePasswordField 
}) => (
    <Form size='large' error={!!signinErrors} onSubmit={() => authSignin(inputs)}>
        <Segment stacked>
            <Form.Input 
                fluid 
                icon='user' 
                type="email"
                iconPosition='left' 
                value={inputs.email}
                placeholder='E-mail address' 
                error={signinErrors && !!signinErrors['email']}
                onChange={(e) => authSigninInputChange('email', e.target.value)}
            />
            <Form.Input
                fluid
                icon='lock'
                iconPosition='left'
                action={
                    <Button 
                    type="button"
                    onClick={authTogglePasswordField}
                    icon={showPassword ? "eye" : "eye slash"} 
                    />
                }
                value={inputs.password}
                placeholder='Password'
                type={showPassword ? 'text' : 'password'}
                error={signinErrors && !!signinErrors['password']}
                onChange={(e) => authSigninInputChange('password', e.target.value)}
            />

            {!!signinErrors && (
                <Message
                    error
                    list={Object.values(signinErrors)}
                />
            )}

            <Button 
                fluid 
                color='teal'
                size='large'
                type='submit'
                loading={isSigningIn}
                disabled={isSigningIn}>
                Signin
            </Button>
        </Segment>

        <Message>
            Forgot your password? <Link to='/auth/reset-password'>Reset it</Link>
        </Message>
    </Form>
);

const mapStateToProps = ({ auth }) => ({
    inputs: auth.signinInputs,
    isSigningIn: auth.signingIn,
    signinErrors: auth.signinErrors,
    showPassword: auth.showPassword,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    authSigninInputChange, authSignin, authTogglePasswordField
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SigninForm);