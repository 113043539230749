import React from 'react';
import { Grid, Button } from 'semantic-ui-react';
import { useSelector, useDispatch } from "react-redux";

import { changeSideNavView } from '../../../common/actions/nav.js';
import { resetSearchCategorySelection } from '../actions.js';
import { hasMarketsliceFilter } from '../helpers.js';

const SearchCategoryContainerMarketslicePickerButtons = () => {
    const dispatch = useDispatch();
    const searchCategory = useSelector(({searchCategory}) => searchCategory);
    const canApply = hasMarketsliceFilter(searchCategory);

    return (
        <Grid columns={2} className="super compact">
            <Grid.Column width={4}>
                <Button
                    fluid
                    size="large"
                    color="grey"
                    content="Cancel"
                    className="straight-border"
                    onClick={() => {
                        dispatch(changeSideNavView(null)); 
                        dispatch(resetSearchCategorySelection(searchCategory.categoryTypes[0]));
                    }}
                />
            </Grid.Column>
            <Grid.Column width={12}>
                <Button
                    fluid
                    primary
                    size="large"
                    icon="checkmark"
                    content="Apply"
                    disabled={!canApply}
                    className="straight-border"
                    onClick={() => dispatch(changeSideNavView(null))}
                />
            </Grid.Column>
        </Grid>
    );
};

export default SearchCategoryContainerMarketslicePickerButtons;