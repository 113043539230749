import React from 'react';
import { useDispatch } from "react-redux";
import { Grid, Button } from 'semantic-ui-react';

import { changeSideNavView } from '../actions/nav.js';

const CommonContainerSideNavBack = () => {
    const dispatch = useDispatch();
    const goBack = () => dispatch(changeSideNavView(null));

    return (
        <Grid columns={1} className="super compact">
            <Grid.Column>
                <Button
                    fluid
                    size="large"
                    color="grey"
                    content="Back"
                    onClick={goBack}
                    className="straight-border"
                />
            </Grid.Column>
        </Grid>
    );
};

export default CommonContainerSideNavBack;