import { isBefore, isAfter } from 'date-fns';

export function dateFilterComparator(filterDate, cellValue) {
    if (isBefore(cellValue, filterDate)) {
        return -1;
    } else if (isAfter(cellValue, filterDate)) {
        return 1;
    } else {
        return 0;
    }
};

// given a set of reducer map with keys referring to the action type and values are functions that handle the reduce action
// this function will return a reducer function that can be linked to redux store
export const generateReducer = (reducers, initialState) => {
    return (state = initialState, action) => {
        if (action.type in reducers) {
            return reducers[action.type](state, action.payload);
        }

        return state;
    };
};

export const gridCurrencyFormatter = (data) => {
    const formattedNumber = Math.round(Number(data.value)).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    return "$" + formattedNumber;
};

export const gridPercentageFormatter = (data) => {
    if (Number(data.value) === 0) {
        return '';
    }

    const formattedNumber = parseFloat(data.value).toFixed(2);
    return `${formattedNumber}%`;
};

export const gridPercentageStyle = (data) => {
    const number = Number(data.value);

    if (number < 0) {
        return { color: 'red' };
    } else if (number > 0) {
        return { color: 'green' };
    }

    return null;
};