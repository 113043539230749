import {
    SEARCH_BOX_TOGGLE,
    SEARCH_BOX_SAVING,
    SEARCH_BOX_SAVED,
} from "../constants";
import { makePrivateApiCall } from "../../common/api";
import { loadEstablishmentReportGrid } from "../../establishment-report/actions";

const buildSearchParams = ({ searchProduct, searchAccount, searchCategory }) => {
    return {
        productType: searchProduct.selectedType,
        productQuery: searchProduct.selected,
    };
};

export const toggleSearchBox = () => (dispatch) => {
    return dispatch({
        type: SEARCH_BOX_TOGGLE
    });
};

export const saveSearch = () => (dispatch, getState) => {
    dispatch({
        type: SEARCH_BOX_SAVING
    });

    return makePrivateApiCall({
        url: 'user/search', 
        method: 'POST', 
        params: buildSearchParams(getState())
    }).then((data) => {
        return dispatch({
            type: SEARCH_BOX_SAVED,
            payload: data,
        });
    }).then(() => dispatch(loadEstablishmentReportGrid()));
};