import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';

const GridPageSizePicker = ({ pageSize, onChange, ...others }) => (
    <Dropdown
        value={pageSize}
        onChange={(e, data) => onChange({pageSize: Number(data.value)})}
        options={[10, 25, 40, 50].map(i => ({ key: i, text: i, value: i }))}
        {...others}
    />
);

GridPageSizePicker.propTypes = {
    onChange: PropTypes.func.isRequired,
    pageSize: PropTypes.number.isRequired,
};

export default GridPageSizePicker;