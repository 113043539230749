import { debounce, isArray, get } from 'lodash';

import {
    SEARCH_ACCOUNT_CHANGE_TYPE,
    SEARCH_ACCOUNT_TOGGLE_INPUT,
    SEARCH_ACCOUNT_CHANGE_QUERY,
    SEARCH_ACCOUNT_SUGGESTIONS_LOADING,
    SEARCH_ACCOUNT_SUGGESTIONS_DATA,
    SEARCH_ACCOUNT_SUGGESTIONS_ERROR,
    SEARCH_ACCOUNT_SELECT,
} from "../constants";
import { makePrivateApiCall } from '../../common/api';

const getSearchAccountSuggestions = debounce((query, limit, dispatch) => {
    dispatch({
        type: SEARCH_ACCOUNT_SUGGESTIONS_LOADING,
    });

    makePrivateApiCall({
        url: 'establishment/suggestions',
        params: { query, limit }
    }).then(res => {
        if (res.data) {
            dispatch({
                type: SEARCH_ACCOUNT_SUGGESTIONS_DATA,
                payload: res.data
            });
        }
    }).catch(err => {
        dispatch({
            type: SEARCH_ACCOUNT_SUGGESTIONS_ERROR,
            payload: get(err, 'response.data.message', null) || err.message
        })
    });
}, 300);

export const changeSearchAccountType = (type) => (dispatch) => {
    return dispatch({
        type: SEARCH_ACCOUNT_CHANGE_TYPE,
        payload: type,
    });
};

export const changeSearchAccountQuery = (query, selectedType) => (dispatch) => {
    if (query.length > 2) {
        getSearchAccountSuggestions(query, selectedType, dispatch);
    }

    return dispatch({
        type: SEARCH_ACCOUNT_CHANGE_QUERY,
        payload: query
    });
};

export const toggleSearchAccountInput = () => (dispatch) => {
    return dispatch({
        type: SEARCH_ACCOUNT_TOGGLE_INPUT
    });
};
export const selectSearchAccount = (product) => (dispatch) => {
    return dispatch({
        type: SEARCH_ACCOUNT_SELECT,
        payload: product,
    });
};
