import React from 'react';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Dropdown, Icon } from 'semantic-ui-react';

import { hasMarketsliceFilter } from '../helpers';
import { changeSideNavView } from '../../../common/actions';
import SearchCategoryComponentLabel from '../marketslice-labels.component';
import { resetSearchCategorySelection, toggleSearchCategorySelection, } from '../actions';


const SearchCategoryContainer = () => {
    const searchCategory = useSelector(({ searchCategory }) => searchCategory);
    const hasMsFilter = hasMarketsliceFilter(searchCategory);
    const hasCustomFilter = !!searchCategory.selectedCustomCategory;
    const hasFilter = hasMsFilter || hasCustomFilter;
    const { selectedCategories, selectedSubCategories, selectedPrices, selectedSizes } = searchCategory;
    
    const dispatch = useDispatch();
    const handleInputToggle = (type) => dispatch(changeSideNavView(type));

    return (
        <div className="bl-4 blc-teal bp-5">
            <Grid columns={2} verticalAlign='middle' padded className="compact">
                <Grid.Column width={6}>
                    Market/Category Comparison
                </Grid.Column>
                <Grid.Column textAlign="right" width={10}>
                    <Dropdown
                        button
                        pointing
                        direction="left"
                        selectOnBlur={false}
                        className="teal tiny"
                        text={ searchCategory.selectedType }
                        value={ searchCategory.selectedType }
                        onChange={(e, {value}) => handleInputToggle(value)}
                        options={searchCategory.categoryTypes.map(type => ({key: type, value: type, text: type}))}
                    />

                    <Icon 
                        onClick={() => hasFilter ? dispatch(resetSearchCategorySelection()) : handleInputToggle(searchCategory.selectedType)}
                        name={ hasFilter ? "close" : "plus"} 
                        className="clickable"
                        color="teal" 
                        size="large" 
                    />
                </Grid.Column>
            </Grid>

            {hasMsFilter && (
                <>
                    {!isEmpty(selectedCategories) && <SearchCategoryComponentLabel
                        header="Category"
                        options={selectedCategories}
                        selected={selectedCategories}
                        onClick={(category) => dispatch(toggleSearchCategorySelection('categories', category))}
                    />}

                    {!isEmpty(selectedSubCategories) && <SearchCategoryComponentLabel
                        header="Subcategory"
                        options={selectedSubCategories}
                        selected={selectedSubCategories}
                        onClick={(subCategory) => dispatch(toggleSearchCategorySelection('subCategories', subCategory))}
                    />}

                    {!isEmpty(selectedPrices) && <SearchCategoryComponentLabel
                        header="Price"
                        options={selectedPrices}
                        selected={selectedPrices}
                        onClick={(price) => dispatch(toggleSearchCategorySelection('prices', price))}
                    />}

                    {!isEmpty(selectedSizes) && <SearchCategoryComponentLabel
                        header="Price"
                        options={selectedSizes}
                        selected={selectedSizes}
                        onClick={(size) => dispatch(toggleSearchCategorySelection('sizes', size))}
                    />}

                </>
            )}
            

            {hasCustomFilter && (
                <Grid columns={1} className="compact" padded>
                    <Grid.Column width={16}>
                        <p className="text-bold">{searchCategory.selectedCustomCategory}</p>
                    </Grid.Column>
                </Grid>
            )}
        </div>
    );
};

export default SearchCategoryContainer;