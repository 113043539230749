import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Button } from 'semantic-ui-react';

import { geoTypeParser, geoNameParser } from '../helpers';

const iconMap = {
    'rndc_house': 'home',
    'city': 'building',
    'rndc_region': 'map',
};

const SettingComponentGeo = ({ geo_type, geo_name, isLoading, onClick }) => {
    const icon = iconMap[geo_type] || 'globe';

    return (
        <Grid columns={2} verticalAlign="middle" padded className="compact">
            <Grid.Column>
                { geoTypeParser(geo_type) }
            </Grid.Column>
            <Grid.Column textAlign="right">
                <Button
                    icon={icon}
                    size="mini"
                    color="grey"
                    loading={isLoading}
                    labelPosition="left"
                    content={geoNameParser(geo_name)}
                    onClick={() => !!onClick && onClick()}
                />
            </Grid.Column>
        </Grid>
    );
};

SettingComponentGeo.propTypes = {
    geo_type: PropTypes.string.isRequired,
    geo_name: PropTypes.string.isRequired,
    isLoading: PropTypes.bool.isRequired,
    onClick: PropTypes.func,
};

export default SettingComponentGeo;