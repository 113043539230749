import axios from "axios";
import { get } from 'lodash';

import { store } from '../store';
import { authExpire } from "../auth/actions";
import { getAuthToken } from '../auth/services/helpers';

const API_URL = process.env.REACT_APP_YDK_INTEL_WEB_API_URL || 'http://192.168.1.122:8090/api';
// const API_URL = process.env.REACT_APP_YDK_INTEL_WEB_API_URL || 'https://v2api.ydr.ink/api';

axios.interceptors.response.use(null, (err) => {
    const status = get(err, 'response.status', null);

    if (status === 401) {
        store.dispatch(authExpire());
    }

    return Promise.reject(err);
});

export const makePrivateApiCall = (config) => {
    const params = { 
        url:'/',
        method:'get', 
        baseURL: API_URL + '/v1', 
        responseType: 'json',
        headers: { 'Authorization': getAuthToken() },
        ...config
    };

    return axios(params);
};

export const makePublicApiCall = (config) => {
    const params = {
        url: '/',
        method: 'get',
        baseURL: API_URL,
        responseType: 'json',
        ...config
    };

    return axios(params);
};