import React from 'react';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { PersistGate } from 'redux-persist/integration/react';
import { SemanticToastContainer } from 'react-semantic-toasts';

import './styles/app.less';

import { store, history, persistor } from "./store";

import AuthMainPage from './auth/pages/main';
import AuthLogoutPage from './auth/pages/logout';

import HomePage from './common/pages/home';
import SettingPage from './common/pages/setting';
import NotFoundPage from './common/pages/not-found';

import PrivateRoute from './common/containers/private-route';

class App extends React.Component {
    render() {
        return (
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <ConnectedRouter history={history}>
                        <div className="app-container">
                            <Switch>
                                <PrivateRoute exact path="/" component={HomePage} />
                                <PrivateRoute exact path="/dashboard" component={HomePage} />
                                <PrivateRoute exact path="/setting" component={SettingPage} />
                                
                                <Route exact path="/logout" component={AuthLogoutPage} />
                                <Route exact path="/auth/:section" component={AuthMainPage} />
                                <Route component={NotFoundPage} />
                            </Switch>

                            <SemanticToastContainer position="top-right"/>
                        </div>
                    </ConnectedRouter>
                </PersistGate>
            </Provider>
        );
    };
};

export default App;
