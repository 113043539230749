import React from 'react';
import { pick, isEmpty } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { Segment, Header, Divider } from 'semantic-ui-react';

import SettingComponentPremise from '../components/premise';
import SettingComponentAlcType from '../components/alc-type';
import SettingComponentOffType from '../components/off-type';
import SettingComponentDateRange from '../components/date-range';
import SettingComponentProductType from '../components/product-type';
import SettingComponentDisplayByUnit from '../components/display-by-unit';

import { changeSetting } from '../actions';
import SettingComponentGeo from '../components/geo';
import { changeSideNavView } from '../../common/actions';
import { PremiseTypes, ProductTypes } from '../constants';

const SettingContainer = () => {
    const dispatch = useDispatch();
    const change = (key, value) => dispatch(changeSetting({[key]: value}));
    const handleDateChange = (rangeType) => {
        if (rangeType === 'custom') {
            return dispatch(changeSideNavView(rangeType));
        }

        return dispatch(changeSetting({sales_period_type: rangeType}));
    };
    const handleGeoClick = () => dispatch(changeSideNavView('geo'));

    const { values, isLoading } = useSelector(({ setting }) => pick(setting, ['values', 'isLoading']));

    if (isEmpty(values)) {
        return (<Segment vertical loading style={{height: 200}}></Segment>);
    }

    return (
        <Segment clearing vertical>
            <div className="sm-10">
                <Header as='h3'>
                    Settings
                </Header>
            </div>

            <Divider clearing />

            <div className="rp-5">
                <SettingComponentProductType
                    isLoading={isLoading.indexOf('service_product_type') >= 0}
                    onChange={change.bind(null, 'service_product_type')}
                    service_product_type={values.service_product_type}
                />
                <SettingComponentPremise
                    isLoading={isLoading.indexOf('service_premises') >= 0}
                    onChange={change.bind(null, 'service_premises')}
                    service_premises={values.service_premises}
                />

                {values.service_premises === PremiseTypes.OFF && values.service_product_type === ProductTypes.LIQUOR && (
                    <SettingComponentOffType
                        isLoading={isLoading.indexOf('off_type') >= 0}
                        onChange={change.bind(null, 'off_type')}
                        off_type={values.off_type}
                    />
                )}

                <SettingComponentGeo
                    onClick={handleGeoClick}
                    geo_name={values.geo_name}
                    geo_type={values.geo_type}
                    isLoading={isLoading.indexOf('geo_name') >= 0}
                />

                <SettingComponentDateRange
                    isLoading={isLoading.indexOf('sales_period_type') >= 0}
                    compare_period_start={values.compare_period_start}
                    compare_period_end={values.compare_period_end}
                    sales_period_start={values.sales_period_start}
                    sales_period_type={values.sales_period_type}
                    sales_period_end={values.sales_period_end}
                    onChange={handleDateChange}
                />

                {values.service_premises === PremiseTypes.ON && (
                    <SettingComponentAlcType
                        isLoading={isLoading.indexOf('alc_type') >= 0}
                        onChange={change.bind(null, 'alc_type')}
                        alc_type={values.alc_type}
                    />
                )}

                <SettingComponentDisplayByUnit
                    isLoading={isLoading.indexOf('display_by_unit') >= 0}
                    onChange={change.bind(null, 'display_by_unit')}
                    display_by_unit={values.display_by_unit}
                />
            </div>
        </Segment>
    );
};

export default SettingContainer;