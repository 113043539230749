import React from 'react';
import { Grid, Button } from 'semantic-ui-react';
import { useSelector, useDispatch } from "react-redux";

import { saveSearch } from './actions.js';

const SearchBoxButtons = () => {
    const dispatch = useDispatch();
    const { isSaving } = useSelector(state => ({
        isSaving: state.searchBox.isSaving,
    }));

    return (
        <Grid columns={2} className="super compact">
            <Grid.Column width={4}>
                <Button
                    fluid
                    size="large"
                    color="grey"
                    content="Reset"
                    className="straight-border"
                />
            </Grid.Column>
            <Grid.Column width={12}>
                <Button
                    fluid
                    primary
                    size="large"
                    icon="search"
                    content="Search"
                    loading={isSaving}
                    className="straight-border"
                    onClick={() => dispatch(saveSearch())}
                />
            </Grid.Column>
        </Grid>
    );
};

export default SearchBoxButtons;