import { get } from 'lodash';

import { 
    ESTABLISHMENT_REPORT_GRID_LOADING, 
    ESTABLISHMENT_REPORT_GRID_SUCCESS, 
    ESTABLISHMENT_REPORT_GRID_ERROR,
    ESTABLISHMENT_REPORT_GRID_SYNC_COLUMNS,
    ESTABLISHMENT_REPORT_GRID_STATE_CHANGE,
    ESTABLISHMENT_REPORT_GRID_TOGGLE, 
} from './constants';
import { makePrivateApiCall } from '../common/api';

export const loadEstablishmentReportGrid = (params) => (dispatch, getState) => {
    dispatch({
        type: ESTABLISHMENT_REPORT_GRID_LOADING
    });

    makePrivateApiCall({
        url: '/establishment/grid',
        method: 'get', params,
    }).then((res) => {
        if (res.data) {
            dispatch({
                type: ESTABLISHMENT_REPORT_GRID_SUCCESS,
                payload: res.data
            });
        }
    }).catch(err => {
        dispatch({
            type: ESTABLISHMENT_REPORT_GRID_ERROR,
            payload: get(err, 'response.data.message', null) || err.message
        });
    });
};

export const syncEstablishmentReportGridColumns = () => (dispatch, getState) => {
    const { setting } = getState();

    dispatch({
        type: ESTABLISHMENT_REPORT_GRID_SYNC_COLUMNS,
        payload: { settings: setting.values, isMarketSearch: false }
    });
};

export const changeEstablishmentReportGridState = (gridState) => dispatch => dispatch({
    type: ESTABLISHMENT_REPORT_GRID_STATE_CHANGE,
    payload: gridState
});

export const toggleEstablishmentReportGrid = () => dispatch => dispatch({
    type: ESTABLISHMENT_REPORT_GRID_TOGGLE,
});