import React from 'react';
import { pick } from 'lodash';
import { useSelector } from 'react-redux';
import { Header } from 'semantic-ui-react';

import { ProductTypes } from '../../setting/constants';
import { geoTypeParser, geoNameParser } from '../../setting/helpers';

const Subheader = () => {
    const {
        geo_name,
        geo_type,
        service_premises,
        service_product_type,
    } = useSelector(({ setting }) => pick(setting.values, [
        'geo_name',
        'geo_type',
        'service_premises',
        'service_product_type',
    ]));

    const productTypeClass = (service_product_type === ProductTypes.LIQUOR) ? 'text-brown' : 'text-purple';

    return (
        <Header.Subheader>
            <span className="text-blue">{service_premises} Premise</span> <span className={productTypeClass}>{service_product_type}</span> data for the <b>{geoNameParser(geo_name)} {geoTypeParser(geo_type)}</b> area.
        </Header.Subheader>
    );
};

export default Subheader;