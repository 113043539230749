import { get } from "lodash";

import {
    SEARCH_CATEGORY_CHANGE_TYPE,
    SEARCH_CATEGORY_CHANGE_KEYWORD,
    SEARCH_CATEGORY_DATA_LOADING,
    SEARCH_CATEGORY_DATA,
    SEARCH_CATEGORY_DATA_ERROR,
    SEARCH_CATEGORY_TOGGLE_SELECTION,
    SEARCH_CATEGORY_RESET_SELECTION,
} from "../constants";
import { makePrivateApiCall } from "../../common/api";

export const changeSearchCategoryType = (type) => (dispatch) => {
    return dispatch({
        type: SEARCH_CATEGORY_CHANGE_TYPE,
        payload: type,
    });
};

export const changeSearchCategoryKeyword = (keyword) => (dispatch) => {
    return dispatch({
        type: SEARCH_CATEGORY_CHANGE_KEYWORD,
        payload: keyword
    });
};

export const resetSearchCategorySelection = (type) => ({
    type: SEARCH_CATEGORY_RESET_SELECTION,
    payload: type
});

export const toggleSearchCategorySelection = (type, value) => (dispatch, getState) => {
    if (type === 'categories') {
        const { searchCategory } = getState();

        if (!searchCategory.subCategories[value]) {
            dispatch(loadSearchSubCategoryData(value));
        }
    }

    return dispatch({
        type: SEARCH_CATEGORY_TOGGLE_SELECTION,
        payload: { type, value }
    });
};

export const loadSearchCategoryData = () => (dispatch) => {
    dispatch({ type: SEARCH_CATEGORY_DATA_LOADING, payload: 'categories' });
    dispatch({ type: SEARCH_CATEGORY_DATA_LOADING, payload: 'sizes' });

    makePrivateApiCall({
        url: 'product/category',
    }).then(res => dispatch({
        type: SEARCH_CATEGORY_DATA,
        payload: { type: 'categories', data: res.data }
    })).catch(err => dispatch({
        type: SEARCH_CATEGORY_DATA_ERROR,
        payload: { type: 'categories', error: get(err, 'response.data.message', null) || err.message }
    }));

    makePrivateApiCall({
        url: 'product/size/group',
    }).then(res => dispatch({
        type: SEARCH_CATEGORY_DATA,
        payload: { type: 'sizes', data: res.data }
    })).catch(err => dispatch({
        type: SEARCH_CATEGORY_DATA_ERROR,
        payload: { type: 'sizes', error: get(err, 'response.data.message', null) || err.message }
    }));
};

export const loadSearchSubCategoryData = (category) => (dispatch) => {
    dispatch({ type: SEARCH_CATEGORY_DATA_LOADING, payload: 'subCategories' });

    makePrivateApiCall({
        url: 'product/category/sub-category',
        params: { category }
    }).then(res => dispatch({
        type: SEARCH_CATEGORY_DATA,
        payload: { type: 'subCategories', data: res.data }
    })).catch(err => dispatch({
        type: SEARCH_CATEGORY_DATA_ERROR,
        payload: { type: 'subCategories', error: get(err, 'response.data.message', null) || err.message }
    }));
};

export const loadSearchCategoryCustomCategories = () => (dispatch) => {
    dispatch({ type: SEARCH_CATEGORY_DATA_LOADING, payload: 'customCategories' });

    makePrivateApiCall({
        url: 'product/custom-category',
    }).then(res => dispatch({
        type: SEARCH_CATEGORY_DATA,
        payload: { type: 'customCategories', data: res.data }
    })).catch(err => dispatch({
        type: SEARCH_CATEGORY_DATA_ERROR,
        payload: { type: 'customCategories', error: get(err, 'response.data.message', null) || err.message }
    }));
};