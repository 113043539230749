import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { debounce, isEqual } from 'lodash';
import { AgGridReact } from 'ag-grid-react';
import { Icon, Segment, Menu, Divider, Pagination } from 'semantic-ui-react';

import { 
    loadEstablishmentReportGrid, 
    toggleEstablishmentReportGrid,
    syncEstablishmentReportGridColumns,
    changeEstablishmentReportGridState, 
} from '../actions';

import CommonContainerColoredNumber from '../../common/components/colored-number';
import GridPageSizePicker from '../../common/components/grid-page-size-picker';

const frameworkComponents = {
    coloredNumberRenderer: CommonContainerColoredNumber,
};

class Grid extends React.Component {
    grid = null;

    componentWillMount() {
        this.props.syncEstablishmentReportGridColumns();
    };

    componentDidMount() {
        // const { loadEstablishmentReportGrid } = this.props;
        // loadEstablishmentReportGrid();
    };

    componentDidUpdate(prevProps) {
        if (!this.grid)
            return;

        if (!isEqual(prevProps.hiddenColumns, this.props.hiddenColumns)) {
            this.syncColumns();
        }

        if (!this.props.isLoading) {
            this.grid.api.hideOverlay();
            this.resizeGrid();
        }

        if (prevProps.pageSize !== this.props.pageSize) {
            this.grid.api.paginationSetPageSize(this.props.pageSize);
        }

        if (prevProps.currentPage !== this.props.currentPage) {
            console.log('going to page', this.props.currentPage);
            this.grid.api.paginationGoToPage(this.props.currentPage);
        }

        if (this.props.isLoading) {
            this.grid.api.showLoadingOverlay();
        }
    };

    resizeGrid = debounce(() => {
        // console.log('resizing grid', this.grid);
        // this.grid && this.grid.api.sizeColumnsToFit();
    }, 300);

    onGridReady = (grid) => {
        this.grid = grid;
        this.syncColumns();

        if (this.props.isLoading) {
            grid.api.showLoadingOverlay();
        }
        
        this.grid.api.paginationSetPageSize(this.props.pageSize);
        this.grid.api.setFilterModel(this.props.filterModel);
        this.grid.api.setSortModel(this.props.sortModel);

        this.grid.api.addEventListener('sortChanged', (e) => {
            this.props.changeEstablishmentReportGridState({ sortModel: this.grid.api.getSortModel() });
        });

        this.grid.api.addEventListener('filterChanged', (e) => {
            this.props.changeEstablishmentReportGridState({ filterModel: this.grid.api.getFilterModel() });
        });
    };

    syncColumns = () => {
        this.grid.columnApi.setColumnsVisible(this.props.hiddenColumns, false);
        this.grid.columnApi.setColumnsVisible(this.props.shownColumns, true);
        this.grid.api.sizeColumnsToFit();
    };

    render() {
        const { data, show, isLoading, pageSize, currentPage, changeEstablishmentReportGridState } = this.props;

        return (
            <>
            <Menu attached={show ? 'top' : false} borderless>
                <Menu.Item
                    header
                    name='Account'
                    onClick={this.handleItemClick}
                >
                    Account
                </Menu.Item>

                <Menu.Menu position="right">
                    {show && (
                        <>
                            <Menu.Item
                                name='download'
                                disabled={isLoading}
                                onClick={this.props.toggleEstablishmentReportGrid}
                            >
                                <Icon name="download" />
                            </Menu.Item>
                        </>
                    )}
                    <Menu.Item
                        name='chevron'
                        active={!!show}
                        disabled={isLoading}
                        onClick={this.props.toggleEstablishmentReportGrid}
                        >
                        <Icon name={show ? "chevron up" : "chevron down"} />
                    </Menu.Item>
                </Menu.Menu>
            </Menu>

            {show && (
                <Segment className="establishment-report" attached>
                    <div className="ag-theme-balham">
                        <AgGridReact
                            rowData={data}
                            pagination={true}
                            floatingFilter={false}
                            domLayout="autoHeight"
                            // paginationPageSize={10}
                            suppressPaginationPanel={true}
                            onGridReady={this.onGridReady}
                            columnDefs={this.props.columnDefs}
                            defaultColDef={{ resizable: true }}
                            frameworkComponents={frameworkComponents}
                        />
                    </div>
                    
                    <div className="tp-10 sp-10 clearing">
                        <GridPageSizePicker
                            button
                            compact
                            pointing="top"
                            pageSize={pageSize}
                            text={`${pageSize}/page`}
                            className="left floated basic"
                            onChange={changeEstablishmentReportGridState}
                        />
                        <Pagination
                            size="mini"
                            floated="right"
                            defaultActivePage={1}
                            totalPages={Math.round(data.length/pageSize)}
                            onPageChange={(e, data) => changeEstablishmentReportGridState({ currentPage: data.activePage-1 })}
                        />
                        <Divider hidden fitted clearing/>
                    </div>
                </Segment>
            )}
            </>
        );
    };
};

const mapStateToProps = ({ establishmentReport }) => ({
    show: establishmentReport.show,
    data: establishmentReport.data,
    error: establishmentReport.error,
    pageSize: establishmentReport.pageSize,
    isLoading: establishmentReport.isLoading,
    sortModel: establishmentReport.sortModel,
    columnDefs: establishmentReport.columnDefs,
    currentPage: establishmentReport.currentPage,
    filterModel: establishmentReport.filterModel,
    shownColumns: establishmentReport.shownColumns,
    hiddenColumns: establishmentReport.hiddenColumns,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    loadEstablishmentReportGrid, 
    toggleEstablishmentReportGrid,
    syncEstablishmentReportGridColumns, 
    changeEstablishmentReportGridState,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Grid);