import React from 'react';
import { pick } from 'lodash';
import { Grid, Button } from 'semantic-ui-react';
import { useSelector, useDispatch } from "react-redux";

import { changeSideNavView } from '../../../common/actions/nav.js';
import { resetSearchCategorySelection } from '../actions.js';

const SearchCategoryContainerCustomPickerButtons = () => {
    const dispatch = useDispatch();
    const { selectedCustomCategory, categoryTypes } = useSelector(({ searchCategory }) => pick(searchCategory, [
        'categoryTypes', 
        'selectedCustomCategory'
    ]));
    const canApply = !!selectedCustomCategory;

    return (
        <Grid columns={2} className="super compact">
            <Grid.Column width={4}>
                <Button
                    fluid
                    size="large"
                    color="grey"
                    content="Cancel"
                    className="straight-border"
                    onClick={() => {
                        dispatch(changeSideNavView(null));
                        dispatch(resetSearchCategorySelection(categoryTypes[1]));
                    }}
                />
            </Grid.Column>
            <Grid.Column width={12}>
                <Button
                    fluid
                    primary
                    size="large"
                    icon="checkmark"
                    content="Apply"
                    disabled={!canApply}
                    className="straight-border"
                    onClick={() => dispatch(changeSideNavView(null))}
                />
            </Grid.Column>
        </Grid>
    );
};

export default SearchCategoryContainerCustomPickerButtons;