import React from 'react';
import { pick } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { MonthRangeInput } from 'semantic-ui-calendar-react';
import { Segment, Header, Divider, Checkbox } from 'semantic-ui-react';

import { dateFormat } from '../helpers';
import { changeSettingSalesPeriod, changeSettingComparePeriod, toggleSettingComparePeriod } from '../actions';

const SettingContainerDate = () => {
    const { values, showCompare, comparePeriod, salesPeriod } = useSelector(({ setting }) => pick(setting, [
            'values', 'customDate', 'showCompare', 'comparePeriod', 'salesPeriod',
        ]));
    
    const dispatch = useDispatch(),
        handleSalesPeriodChange = (e, {value}) => dispatch(changeSettingSalesPeriod(value)),
        handleComparePeriodChange = (e, {value}) => dispatch(changeSettingComparePeriod(value)),
        handleComparePeriodToggle = (e, { checked }) => dispatch(toggleSettingComparePeriod(checked));

    return (
        <>
        <Segment vertical>
            <div className="sm-10">
                <Header>
                    Custom Date Range
                </Header>
            </div>
        </Segment>

        <Segment vertical>
            <div className="sp-10 bp-15">
                <Header 
                    as="h4"
                    content="Current"
                />
                <MonthRangeInput
                    inline
                    name='period'
                    value={salesPeriod}
                    dateFormat={dateFormat}
                    popupPosition='bottom right'
                    onChange={handleSalesPeriodChange}
                />
            </div>
            <div className="sp-10 bp-15">
                <Header 
                    as="h4"
                    floated="left"
                    content="Last Period?"
                />
                
                <Checkbox 
                    toggle 
                    floating="right" 
                    checked={showCompare}
                    onChange={handleComparePeriodToggle}
                />

                <Divider hidden clearing fitted/>
                { showCompare && (
                    <MonthRangeInput
                        inline
                        name='comparePeriod'
                        value={comparePeriod}
                        dateFormat={dateFormat}
                        popupPosition='bottom right'
                        onChange={handleComparePeriodChange}
                    />
                ) }
            </div>
        </Segment>
        </>
    );
};

export default SettingContainerDate;