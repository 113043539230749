import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Button } from 'semantic-ui-react';

import { ProductTypes } from '../constants';

const SettingComponentProductType = ({ service_product_type, isLoading, onChange }) => {
    return (
        <Grid columns={2} verticalAlign="middle" padded className="compact">
            <Grid.Column width={6}>
                Shipments For
            </Grid.Column>
            <Grid.Column width={10} textAlign="right">
                <Button.Group size="tiny">
                    <Button
                        loading={isLoading}
                        content={ProductTypes.LIQUOR}
                        onClick={() => !!onChange && onChange(ProductTypes.LIQUOR)}
                        color={service_product_type === ProductTypes.LIQUOR ? 'brown' : 'grey'}
                    />
                    <Button.Or />
                    <Button 
                        loading={isLoading}
                        content={ProductTypes.WINE}
                        onClick={() => !!onChange && onChange(ProductTypes.WINE)}
                        color={service_product_type === ProductTypes.WINE ? 'purple' : 'grey'}
                    />
                </Button.Group>
            </Grid.Column>
        </Grid>
    );
};

SettingComponentProductType.propTypes = {
    service_product_type: PropTypes.oneOf(Object.values(ProductTypes)).isRequired,
    isLoading: PropTypes.bool.isRequired,
    onChange: PropTypes.func,
};

export default SettingComponentProductType;