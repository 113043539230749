import React from 'react';
import { useSelector } from "react-redux";
import { Scrollbars } from 'react-custom-scrollbars';

import SettingContainer from '../../setting/containers';
import CommonContainerSideNavBack from './side-nav-back';
import SearchBoxButtons from '../../search/box/buttons.js';
import CommonContainerSideNavHeader from './side-nav-header.js';
import SettingContainerDate from '../../setting/containers/date';
import SearchBoxContainer from '../../search/box/index.container.js';
import SettingContainerDateButtons from '../../setting/containers/date-buttons';
import SearchCategoryContainerCustomPicker from '../../search/category/containers/custom-picker.js';
import SearchCategoryContainerMarketslicePicker from '../../search/category/containers/marketslice-picker.js';
import SearchCategoryContainerCustomPickerButtons from '../../search/category/containers/custom-picker-buttons.js';
import SearchCategoryContainerMarketslicePickerButtons from '../../search/category/containers/marketslice-picker-buttons.js';
import SettingContainerGeo from '../../setting/containers/geo';

const SideNav = () => {
    const { view, categoryTypes } = useSelector(({ nav, searchCategory }) => ({
        view: nav.view, 
        categoryTypes: searchCategory.categoryTypes
    }));

    return (
    <>
        <Scrollbars 
            autoHide 
            className="page-sidenav-scrollable-content"
        >
            <CommonContainerSideNavHeader />
            
            {!view && (
                <>
                <SearchBoxContainer />
                <SettingContainer />
                </>
            )}

            {view === categoryTypes[0] && (
                <SearchCategoryContainerMarketslicePicker />
            )}

            {view === categoryTypes[1] && (
                <SearchCategoryContainerCustomPicker />
            )}

            {view === 'custom' && (
                <SettingContainerDate />
            )}

            {view === 'geo' && (
                <SettingContainerGeo />
            )}
        </Scrollbars>
        
        <div>
            {!view && (
                <SearchBoxButtons />
            )}
            {view === categoryTypes[0] && (
                <SearchCategoryContainerMarketslicePickerButtons />
            ) }
            {view === categoryTypes[1] && (
                <SearchCategoryContainerCustomPickerButtons />
            ) }
            {view === 'custom' && (
                <SettingContainerDateButtons />
            ) }
            {view === 'geo' && (
                <CommonContainerSideNavBack />
            ) }
        </div>
    </>
    );
};

export default SideNav;