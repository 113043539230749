import React from 'react';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import { Grid, Dropdown } from 'semantic-ui-react';

import { dateFormat } from '../helpers';
import { DateTypes } from '../constants';

const DropdownOptions = Object.keys(DateTypes).map(type => {
    return {
        key: type, text: DateTypes[type], value: type
    };
});

const SettingComponentDateRange = ({ 
    sales_period_type, 

    compare_period_start, 
    sales_period_start, 
    compare_period_end,
    sales_period_end,
    
    isLoading, 
    onChange, 
}) => {
    const selected = DropdownOptions.find(option => option.value.toLowerCase() === sales_period_type.toLowerCase());

    return (
        <Grid columns={2} verticalAlign="middle" padded className="compact">
            <Grid.Column>
                Current: <b>{format(sales_period_start, dateFormat)} - {format(sales_period_end, dateFormat)}</b>
                {sales_period_type === 'custom' && (
                    <>
                    <br/>
                    Last Period: <b>{format(compare_period_start, dateFormat)} - {format(compare_period_end, dateFormat)}</b>
                    </>
                )}
                </Grid.Column>
            <Grid.Column textAlign="right">
                <Dropdown
                    button
                    labeled
                    pointing
                    direction="left"
                    loading={isLoading}
                    selectOnBlur={false}
                    text={selected.text}
                    value={selected.value}
                    options={DropdownOptions}
                    className="icon tiny grey"
                    onChange={(e, { value }) => !!onChange && onChange(value)}
                    icon={selected.value === 'custom' ? 'calendar alternate outline' : 'checked calendar'}
                />
            </Grid.Column>
        </Grid>
    );
};

SettingComponentDateRange.propTypes = {
    sales_period_type: PropTypes.oneOf(Object.keys(DateTypes)).isRequired,
    sales_period_start: PropTypes.string.isRequired,
    sales_period_end: PropTypes.string.isRequired,
    isLoading: PropTypes.bool.isRequired,
    onChange: PropTypes.func,
};

export default SettingComponentDateRange;