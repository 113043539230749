export const SETTING_CHANGE_REQUEST = 'SETTING_CHANGE_REQUEST';
export const SETTING_CHANGE_LOADING = 'SETTING_CHANGE_LOADING';
export const SETTING_CHANGE_SUCCESS = 'SETTING_CHANGE_SUCCESS';
export const SETTING_CHANGE_ERROR = 'SETTING_CHANGE_ERROR';

export const SETTING_GEO_OPTIONS = 'SETTING_GEO_OPTIONS';
export const SETTING_GEO_SEARCH_QUERY = 'SETTING_GEO_SEARCH_QUERY';
export const SETTING_GEO_SEARCH_LOADING = 'SETTING_GEO_SEARCH_LOADING';
export const SETTING_GEO_SEARCH_RESULTS = 'SETTING_GEO_SEARCH_RESULTS';

export const SETTING_TOGGLE_COMPARE_PERIOD = 'SETTING_TOGGLE_COMPARE_PERIOD';
export const SETTING_CHANGE_COMPARE_PERIOD = 'SETTING_CHANGE_COMPARE_PERIOD';
export const SETTING_CHANGE_SALES_PERIOD = 'SETTING_CHANGE_SALES_PERIOD';

export const PremiseTypes = {
    ON:     'On', 
    OFF:    'Off',
};

export const ProductTypes = {
    LIQUOR: 'Liquor',
    WINE: 'Wine',
};

export const AlcTypes = {
    ALL: 'All Bev', 
    LIQUOR: 'Liquor', 
    WINE: 'Wine', 
    BEER: 'Beer',
};

export const DisplayByUnits = {
    CASES: 'Cases', 
    UNITS: 'Units', 
    SALES: 'Sales',
};

export const DateTypes = {
    YTD: 'Year To Date', 
    "12Months": 'Rolling 12 Months', 
    CurrentMonth: 'Current Month',
    Custom: 'Custom',
    Weekly: 'Weekly',
};

export const OffTypes = [
    'retail only',
    'wholesale only',
    'retail & wholesale (mix)',
    'all accounts',
];

export const DefaultSettings = {
    alc_type: "Liquor",
    compare_period_end: "2019-02-17",
    compare_period_start: "2019-02-05",
    display_by_unit: "Units",
    geo_name: "Koelpinmouth",
    geo_type: "city",
    off_type: "2",
    sales_period_end: "2019-01-24",
    sales_period_start: "2019-03-09",
    sales_period_type: 'YTD',
    service_premises: "On",
    service_product_type: "Liquor",
    show_by: "Label",
};