import React from 'react';
import { flatten, startCase } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { Grid, Dropdown, Icon, Header } from 'semantic-ui-react';

import {
    selectSearchAccount,
    changeSearchAccountType,
    toggleSearchAccountInput,
    changeSearchAccountQuery,
} from './actions';

const SearchAccountContainer = () => {
    const { 
        accountTypes, 
        selectedType, 
        suggestions,
        inputQuery,
        showInput, 
        isLoading,
        selected, 
        error,
    } = useSelector(({ searchAccount }) => searchAccount);

    const options = flatten(Object.keys(suggestions).map(key => {
        return suggestions[key].map(({ value, address, city, title, division, region }) => ({
            key: `${value} (${startCase(key)})`,
            text: `${value} (${startCase(key)})`,
            value: `${value} (${startCase(key)})`,
            // the subheader generation is a bit tricky here, we throw all the possible option fields into an array and then filter out anything that is not truthy
            // remaining elements are all strings so we just join them with a comma in the middle
            // this is a bit dependent on the backend which is smart enough to only send title, division etc. for reps and address, city etc. for ests 
            content: (<Header 
                size="tiny" 
                content={`${value} (${key})`} 
                subheader={[address, city, title, division, region].filter(Boolean).join(', ')} 
            />),
        }));
    }));

    const dispatch = useDispatch();
    const handleChange = (e, { value }) => dispatch(selectSearchAccount(value));
    const handleSearchChange = (e, { searchQuery }) => dispatch(changeSearchAccountQuery(searchQuery, selectedType));

    return (
        <div className="bl-4 blc-violet bp-5">
        <Grid columns={2} verticalAlign='middle' padded className="compact">
            <Grid.Column width={4}>
                Accounts
            </Grid.Column>
            <Grid.Column textAlign="right" width={12}>
                <Icon 
                    name="map marker alternate" 
                    className="clickable"
                    color="violet" 
                    size="large" 
                />
                <Dropdown
                    button
                    pointing
                    direction="left"
                    text={selectedType}
                    className="tiny violet"
                    onChange={(e, { value }) => dispatch(changeSearchAccountType(value))}
                    options={accountTypes.map(type => ({ key: type, value: type, text: type }))}
                />

                <Icon 
                    onClick={() => dispatch(toggleSearchAccountInput())}
                    name={ showInput ? "close" : "plus"} 
                    className="clickable"
                    color="violet" 
                    size="large" 
                />
            </Grid.Column>
        </Grid>

        { showInput && (
            <div className="lp-5 rp-15">
                <Dropdown
                    fluid
                    search
                    selection
                    clearable
                    error={!!error}
                    value={selected}
                    minCharacters={3}
                    options={options}
                    autoComplete="off"
                    loading={isLoading}
                    selectOnBlur={false}
                    onChange={handleChange}
                    searchQuery={inputQuery}
                    onSearchChange={handleSearchChange}
                    icon={inputQuery.length > 2 ? 'dropdown' : null}
                    placeholder={`Start typing to see suggestions...`}
                    noResultsMessage={inputQuery.length > 2 ? `Sorry, no results found for "${inputQuery}"` : null}
                />
            </div>
        ) }
        </div>
    );
};

export default SearchAccountContainer;