import React from 'react';
import pluralize from 'pluralize';
import PropTypes from 'prop-types';
import { Label } from 'semantic-ui-react';

const SearchCategoryComponentLabel = ({ header, options, selected, onClick }) => {
    return (
        <div className="sp-5">
            <Label.Group>
                {!!header && (
                    <Label 
                        basic 
                        pointing="right" 
                        content={ pluralize(header, options.length) }
                    />
                )}
                
                {options.map(option => (<Label
                    as="a"
                    key={option}
                    content={option}
                    onClick={() => onClick(option)}
                    basic={selected.indexOf(option) < 0}
                    color={selected.indexOf(option) < 0 ? null : 'grey'}
                />))}
            </Label.Group>
        </div>
    );
};

SearchCategoryComponentLabel.propTypes = {
    header: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    options: PropTypes.array.isRequired,
    selected: PropTypes.array.isRequired,
};

export default SearchCategoryComponentLabel;