import { isEmpty } from 'lodash';

export const hasMarketsliceFilter = ({ 
    selectedSizes,
    selectedPrices,
    selectedCategories,
    selectedSubCategories,
}) => {
    return !isEmpty(selectedSizes) 
        || !isEmpty(selectedPrices) 
        || !isEmpty(selectedCategories) 
        || !isEmpty(selectedSubCategories);
};