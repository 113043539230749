import {
    productTypes,
    SEARCH_PRODUCT_SELECT,
    SEARCH_PRODUCT_CHANGE_TYPE,
    SEARCH_PRODUCT_TOGGLE_INPUT,
    SEARCH_PRODUCT_CHANGE_QUERY,
    SEARCH_PRODUCT_SUGGESTIONS_DATA,
    SEARCH_PRODUCT_SUGGESTIONS_ERROR,
    SEARCH_PRODUCT_SUGGESTIONS_LOADING,
} from "../constants";
import { generateReducer } from "../../common/helpers";


const initialState = {
    error: null,
    productTypes,
    selected: '',
    inputQuery: '',
    suggestions: [],
    isLoading: false,
    showInput: false,
    selectedType: productTypes[0],
};

const setLoading = (state) => ({ ...state, isLoading: true });
const changeQuery = (state, inputQuery) => ({ ...state, inputQuery });
const toggleInput = (state) => {
    const showInput = !state.showInput,
        newState = { ...state, showInput };

    // if hiding, previous selections, suggestions and input should be cleaned up
    if (!showInput) {
        newState.selected = '';
        newState.inputQuery = '';
        newState.suggestions = [];
    }

    return newState;
};
const changeType = (state, selectedType) => ({ ...state, selectedType, suggestions: [], selected: '', inputQuery: '' });

const selectProduct = (state, selected) => ({ ...state, selected, inputQuery: selected });
const setSuggestionError = (state, error) => ({ ...state, isLoading: false, error });
const setSuggestions = (state, suggestions) => ({ ...state, suggestions, isLoading: false, error: null });

const reducers = {
    [SEARCH_PRODUCT_CHANGE_TYPE]: changeType,
    [SEARCH_PRODUCT_TOGGLE_INPUT]: toggleInput,
    [SEARCH_PRODUCT_CHANGE_QUERY]: changeQuery,
    
    [SEARCH_PRODUCT_SELECT]: selectProduct,
    [SEARCH_PRODUCT_SUGGESTIONS_LOADING]: setLoading,
    [SEARCH_PRODUCT_SUGGESTIONS_DATA]: setSuggestions,
    [SEARCH_PRODUCT_SUGGESTIONS_ERROR]: setSuggestionError,
};

export default generateReducer(reducers, initialState);