import { debounce, get, isArray } from 'lodash';

import { 
    SEARCH_PRODUCT_SELECT,
    SEARCH_PRODUCT_CHANGE_TYPE, 
    SEARCH_PRODUCT_TOGGLE_INPUT, 
    SEARCH_PRODUCT_CHANGE_QUERY, 
    SEARCH_PRODUCT_SUGGESTIONS_DATA,
    SEARCH_PRODUCT_SUGGESTIONS_ERROR,
    SEARCH_PRODUCT_SUGGESTIONS_LOADING,
} from "../constants";
import { makePrivateApiCall } from '../../common/api';

export const changeSearchProductType = (type) => (dispatch) => {
    return dispatch({
        type: SEARCH_PRODUCT_CHANGE_TYPE,
        payload: type,
    });
};

const getSearchProductSuggestions = debounce((query, limit, dispatch) => {
    dispatch({
        type: SEARCH_PRODUCT_SUGGESTIONS_LOADING,
    });

    makePrivateApiCall({
        url: 'product/suggestions',
        params: {query, limit}
    }).then(res => {
        if (res.data && isArray(res.data)) {
            dispatch({
                type: SEARCH_PRODUCT_SUGGESTIONS_DATA,
                payload: res.data
            });
        }
    }).catch(err => {
        dispatch({
            type: SEARCH_PRODUCT_SUGGESTIONS_ERROR,
            payload: get(err, 'response.data.message', null) || err.message
        })
    });
}, 300);

export const changeSearchProductQuery = (query, selectedType) => (dispatch) => {
    if (query.length > 2) {
        getSearchProductSuggestions(query, selectedType, dispatch);
    }

    return dispatch({
        type: SEARCH_PRODUCT_CHANGE_QUERY,
        payload: query
    });
};

export const toggleSearchProductInput = () => (dispatch) => {
    return dispatch({
        type: SEARCH_PRODUCT_TOGGLE_INPUT
    });
};

export const selectSearchProduct = (product) => (dispatch) => {
    return dispatch({
        type: SEARCH_PRODUCT_SELECT,
        payload: product,
    });
};