import { mapValues, keyBy } from 'lodash';

import { 
    SETTING_CHANGE_REQUEST, 
    SETTING_CHANGE_SUCCESS, 
    SETTING_CHANGE_ERROR,
    SETTING_TOGGLE_COMPARE_PERIOD,
    SETTING_CHANGE_COMPARE_PERIOD,
    SETTING_CHANGE_SALES_PERIOD,
    SETTING_GEO_OPTIONS,
    SETTING_GEO_SEARCH_QUERY,
    SETTING_GEO_SEARCH_LOADING,
    SETTING_GEO_SEARCH_RESULTS
} from "./constants";
import { buildDateRange } from './helpers';
import { generateReducer } from "../common/helpers";
import { AUTH_SIGNIN_SUCCESS } from '../auth/constants';

const initialState = {
    showCompare: false,
    comparePeriod: '',
    salesPeriod: '',

    namedValues: {},
    isLoading: [],
    errors: {},
    values: {},
    data: [],

    geoOptions: {},
    geoSearchQuery: '',
    geoSearchResults: {},
    geoSearchLoading: false,
};

const setLoading = (state, settings) => ({ ...state, isLoading: Object.keys(settings) });
const setError = (state, error) => ({ ...state, isLoading: [], error });
const setData = (state, data) => {
    const namedValues = keyBy(data, 'name'),
        values = mapValues(namedValues, 'value');
    
    return { 
        ...state, 
        data, 
        values, 
        namedValues,
        isLoading: [],
        salesPeriod: buildDateRange(values.sales_period_start, values.sales_period_end), 
        comparePeriod: buildDateRange(values.compare_period_start, values.compare_period_end),
    };
};

const toggleComparePeriod = (state) => ({...state, showCompare: !state.showCompare});
const changeComparePeriod = (state, value) => ({...state, comparePeriod: value});
const changeSalesPeriod = (state, value) => ({...state, salesPeriod: value});

const loadGeoOptions = (state, geoOptions) => ({ ...state, geoOptions });
const setGeoSearchLoading = (state, geoSearchLoading) => ({ ...state, geoSearchLoading });
const setGeoSearchResults = (state, geoSearchResults) => ({ ...state, geoSearchResults, geoSearchLoading: false });
const setGeoSearchQuery = (state, geoSearchQuery) => ({ ...state, geoSearchQuery, geoSearchResults: !geoSearchQuery ? {} : state.geoSearchResults });

const reducers = {
    [SETTING_CHANGE_ERROR]: setError,
    [SETTING_CHANGE_SUCCESS]: setData,
    [SETTING_CHANGE_REQUEST]: setLoading,

    [SETTING_CHANGE_SALES_PERIOD]: changeSalesPeriod,
    [SETTING_TOGGLE_COMPARE_PERIOD]: toggleComparePeriod,
    [SETTING_CHANGE_COMPARE_PERIOD]: changeComparePeriod,

    [SETTING_GEO_OPTIONS]: loadGeoOptions,
    [SETTING_GEO_SEARCH_QUERY]: setGeoSearchQuery,
    [SETTING_GEO_SEARCH_LOADING]: setGeoSearchLoading,
    [SETTING_GEO_SEARCH_RESULTS]: setGeoSearchResults,

    [AUTH_SIGNIN_SUCCESS]: (state, { settings }) => setData(state, settings),
};

export default generateReducer(reducers, initialState);