import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Dropdown } from 'semantic-ui-react';

import { DisplayByUnits } from '../constants';

const  iconMap = {
    'units': 'square',
    'cases': 'box',
    'sales': 'dollar',
};

const DropdownOptions = Object.values(DisplayByUnits).map(type => ({
    key: type, text: type, value: type, icon: iconMap[type.toLowerCase()]
}));

const SettingComponentDisplayByUnit = ({ display_by_unit, onChange, isLoading }) => {
    const selected = DropdownOptions.find(option => option.value === display_by_unit);

    return (
        <Grid columns={2} verticalAlign="middle" padded className="compact">
            <Grid.Column>
                Display Unit
            </Grid.Column>
            <Grid.Column textAlign="right">
                <Dropdown 
                    button 
                    labeled 
                    pointing
                    direction="left"
                    loading={isLoading}
                    icon={selected.icon} 
                    text={selected.text} 
                    selectOnBlur={false}
                    options={DropdownOptions}
                    className="icon tiny grey"
                    onChange={(e, { value }) => !!onChange && onChange(value)} 
                />
            </Grid.Column>
        </Grid>
    );
};

SettingComponentDisplayByUnit.propTypes = {
    display_by_unit: PropTypes.oneOf(Object.values(DisplayByUnits)).isRequired,
    isLoading: PropTypes.bool.isRequired,
    onChange: PropTypes.func,
};

export default SettingComponentDisplayByUnit;