export const SEARCH_BOX_SAVED = 'SEARCH_BOX_SAVED';
export const SEARCH_BOX_SAVING = 'SEARCH_BOX_SAVING';
export const SEARCH_BOX_TOGGLE = 'SEARCH_BOX_TOGGLE';

export const SEARCH_PRODUCT_SELECT = 'SEARCH_PRODUCT_SELECT';
export const SEARCH_PRODUCT_CHANGE_TYPE = 'SEARCH_PRODUCT_CHANGE_TYPE';
export const SEARCH_PRODUCT_TOGGLE_INPUT = 'SEARCH_PRODUCT_TOGGLE_INPUT';
export const SEARCH_PRODUCT_CHANGE_QUERY = 'SEARCH_PRODUCT_CHANGE_QUERY';
export const SEARCH_PRODUCT_SUGGESTIONS_DATA = 'SEARCH_PRODUCT_SUGGESTIONS_DATA';
export const SEARCH_PRODUCT_SUGGESTIONS_ERROR = 'SEARCH_PRODUCT_SUGGESTIONS_ERROR';
export const SEARCH_PRODUCT_SUGGESTIONS_LOADING = 'SEARCH_PRODUCT_SUGGESTIONS_LOADING';

export const SEARCH_CATEGORY_CHANGE_TYPE = 'SEARCH_CATEGORY_CHANGE_TYPE';
export const SEARCH_CATEGORY_TOGGLE_INPUT = 'SEARCH_CATEGORY_TOGGLE_INPUT';
export const SEARCH_CATEGORY_CHANGE_KEYWORD = 'SEARCH_CATEGORY_CHANGE_KEYWORD';
export const SEARCH_CATEGORY_RESET_SELECTION = 'SEARCH_CATEGORY_RESET_SELECTION';
export const SEARCH_CATEGORY_TOGGLE_SELECTION = 'SEARCH_CATEGORY_TOGGLE_SELECTION';
export const SEARCH_CATEGORY_DATA = 'SEARCH_CATEGORY_DATA';
export const SEARCH_CATEGORY_DATA_ERROR = 'SEARCH_CATEGORY_DATA_ERROR';
export const SEARCH_CATEGORY_DATA_LOADING = 'SEARCH_CATEGORY_DATA_LOADING';

export const SEARCH_ACCOUNT_SELECT = 'SEARCH_ACCOUNT_SELECT';
export const SEARCH_ACCOUNT_CHANGE_TYPE = 'SEARCH_ACCOUNT_CHANGE_TYPE';
export const SEARCH_ACCOUNT_TOGGLE_INPUT = 'SEARCH_ACCOUNT_TOGGLE_INPUT';
export const SEARCH_ACCOUNT_CHANGE_QUERY = 'SEARCH_ACCOUNT_CHANGE_QUERY';
export const SEARCH_ACCOUNT_SUGGESTIONS_DATA = 'SEARCH_ACCOUNT_SUGGESTIONS_DATA';
export const SEARCH_ACCOUNT_SUGGESTIONS_ERROR = 'SEARCH_ACCOUNT_SUGGESTIONS_ERROR';
export const SEARCH_ACCOUNT_SUGGESTIONS_LOADING = 'SEARCH_ACCOUNT_SUGGESTIONS_LOADING';

export const productTypes = ["Brand", "Label", "Product", "Category", "Portfolio", "Supplier", "Division"];
export const categoryTypes = ["Marketslice", "Custom Category"];
