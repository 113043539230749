import { startCase } from 'lodash';
import { format, parse } from "date-fns";

export const dateFormat = 'MMM YYYY';
export const buildDateRange = (start, end) => `${format(start, dateFormat)} - ${format(end, dateFormat)}`;
export const parseDateRange = (range) => {
    const dates = range.split(' - ');

    if (dates.length < 1) {
        return [null, null];
    }

    return [parse(dates[0]), parse(dates[1])];
};

export const geoTypeParser = (geo_type = '') => startCase(geo_type.replace('rndc_', '').replace('tabc_', ''));
export const geoNameParser = (geo_name = '') => startCase(geo_name);