import React from 'react';
import { startCase } from 'lodash';
import PropTypes from 'prop-types';
import { Grid, Dropdown } from 'semantic-ui-react';

import { OffTypes } from '../constants';

const iconMap = ["shopping basket", "truck", "archive", "star"];
const DropdownOptions = OffTypes.map((type, i) => ({
    key: type, text: startCase(type), value: i, icon: iconMap[i]
}));

const SettingComponentOffType = ({ off_type, onChange, isLoading }) => {
    const selected = DropdownOptions.find(option => option.value === parseInt(off_type));

    return (
        <Grid columns={2} verticalAlign="middle" padded className="compact">
            <Grid.Column width={6}>
                Off Premise Location
            </Grid.Column>
            <Grid.Column textAlign="right" width={10}>
                <Dropdown
                    button
                    labeled
                    pointing
                    direction="left"
                    loading={isLoading}
                    icon={selected.icon}
                    text={selected.text}
                    options={DropdownOptions}
                    className="icon tiny grey"
                    onChange={(e, { value }) => !!onChange && onChange(value)} 
                />
            </Grid.Column>
        </Grid>
    );
};

SettingComponentOffType.propTypes = {
    off_type: PropTypes.oneOf(Object.keys(OffTypes)).isRequired,
    isLoading: PropTypes.bool.isRequired,
    onChange: PropTypes.func,
};

export default SettingComponentOffType;