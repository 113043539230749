import React from 'react';
import { Divider, Button, Header, Segment } from 'semantic-ui-react';

import Subheader from './subheader.container';

const ProductReportContainerGrid = () => {
    return (
        <Segment className="product-report">
            <Header floated="left">
                <Header.Content>
                    Product
                    <Subheader />
                </Header.Content>
            </Header>

            <Button.Group 
                floated="right"
                size="mini"
                compact
            >
                <Button
                    icon='download'
                />
                <Button
                    icon='chevron up'
                />
            </Button.Group>

            <Divider clearing hidden fitted />
        </Segment>
    );
};

export default ProductReportContainerGrid;