import { keyBy } from 'lodash';
import { gridCurrencyFormatter, gridPercentageFormatter, gridPercentageStyle } from '../common/helpers';

/**
 * Given the current settings, search criteria etc. this function generates ag-grid compatible columnDefiniton but they are keyed by column name
 * for the ease of configuring column view using column name only
 * 
 * @param {array} settings user's current settings in object format based on which columns will be shown
 * @param {object} reportState If the user is looking at market data, columns show % of MKT
 * @param {boolean} isMarketSearch If the user is looking at market data, columns show % of MKT
 * @return {object} Returns an object keyed by column name containing definition objects that ag-grid can use
 *
 */
export const getEstablishmentReportGridColumns = (settings = {}, reportState = {}, isMarketSearch) => {
    const categoryOrMarket = isMarketSearch ? 'Mkt' : "Cat";

    const name = {
        headerName: "Name",
        sortable: true,
        autoHeight: true,
        filter: 'agTextColumnFilter',
        minWidth: 200,
        field: "name",
        cellStyle: {'white-space': 'normal', 'line-height': '1.5em'},
    };
    
    const accounts = {
        headerName: "Acct",
        sortable: true,
        autoHeight: true,
        filter: 'agTextColumnFilter',
        field: "accounts",
        cellStyle: {'white-space': 'normal', 'line-height': '1.5em'},
    };
    
    const corporate = {
        headerName: "Corporate",
        autoHeight: true,
        sortable: true,
        filter: 'agNumberColumnFilter',
        field: "corporate",
        cellStyle: { 'white-space': 'normal', 'line-height': '1.5em' },
    };
    
    const vol_type = {
        headerName: settings.display_by_unit,
        sortable: true,
        filter: 'agNumberColumnFilter',
        valueFormatter: gridCurrencyFormatter,
        field: "vol",
        type: "numericColumn",
    };
    
    const vol_type_lp = {
        headerName: `${settings.display_by_unit} LP`,
        sortable: true,
        filter: 'agNumberColumnFilter',
        valueFormatter: gridCurrencyFormatter,
        field: "vol_lp",
        type: "numericColumn",
    };
    
    const vol_diff = {
        headerName: "% Diff",
        sortable: true,
        filter: 'agNumberColumnFilter',
        valueFormatter: gridPercentageFormatter,
        cellStyle: gridPercentageStyle,
        field: "vol_diff",
        type: "numericColumn",
    };
    
    const percent_category = {
        headerName: "% of " + categoryOrMarket,
        sortable: true,
        filter: 'agNumberColumnFilter',
        valueFormatter: gridPercentageFormatter,
        // cellStyle: gridPercentageStyle,
        field: "percent_category",
        type: "numericColumn",
    };
    
    const category = {
        headerName: categoryOrMarket + " Vol",
        sortable: true,
        filter: 'agNumberColumnFilter',
        valueFormatter: gridCurrencyFormatter,
        field: "category",
        type: "numericColumn",
    };
    
    const category_lp = {
        headerName: categoryOrMarket + " Vol LP",
        sortable: true,
        filter: 'agNumberColumnFilter',
        valueFormatter: gridCurrencyFormatter,
        field: "category_lp",
        type: "numericColumn",
    };

    const category_diff = {
        headerName: categoryOrMarket + " % Diff",
        sortable: true,
        filter: 'agNumberColumnFilter',
        valueFormatter: gridPercentageFormatter,
        cellStyle: gridPercentageStyle,
        field: "category_diff",
        type: "numericColumn",
    };

    const sales = {
        field: "sales",
        headerName: `${settings.alc_type}`,
        sortable: true,
        filter: 'agNumberColumnFilter',
        valueFormatter: gridCurrencyFormatter,
        type: "numericColumn",
    };

    const sales_lp = {
        field: "sales_lp",
        headerName: `${settings.alc_type} LP`,
        sortable: true,
        filter: 'agNumberColumnFilter',
        valueFormatter: gridCurrencyFormatter,
        type: "numericColumn",
    }; 

    const sales_diff = {
        headerName: "% Diff",
        sortable: true,
        filter: 'agNumberColumnFilter',
        valueFormatter: gridPercentageFormatter,
        cellStyle: gridPercentageStyle,
        field: "sales_diff",
        type: "numericColumn",
    };

    const isSales = settings.vol_type && (settings.vol_type.toLowerCase() === 'sales'),
        isLiquor = settings.service_product_type && (settings.service_product_type.toLowerCase() === 'liquor'),
        isOnPrem = settings.service_premises && (settings.service_premises.toLowerCase() === 'on'),
        isComplete = settings.service_line && (settings.service_line.toLowerCase() === "complete");
        
    let columns = keyBy([
        name,
        // accounts,
        // corporate,

        vol_type,
        vol_type_lp,
        vol_diff,
        
        percent_category,
        category,
        category_lp,
        category_diff,
        
        sales,
        sales_lp,
        sales_diff,
    ], 'field');

    // sales columns available for OFF prem and Complete
    if (!isComplete || !isOnPrem) {
        // delete columns['sales'];
        // delete columns['sales_lp'];
        // delete columns['sales_diff'];
    }
    
    return columns;
};