import { pick, startCase } from 'lodash';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Header, Segment, Divider } from 'semantic-ui-react';

import { loadSearchCategoryData, toggleSearchCategorySelection,  } from '../actions';
import SearchCategoryComponentLabel from '../marketslice-labels.component';

const SearchCategoryContainerMarketslicePicker = () => {
    const { 
        service_product_type,

        subCategories,
        categories,
        prices,
        sizes,

        selectedSubCategories,
        selectedCategories,
        selectedPrices,
        selectedSizes,
        
        isLoading,
        errors,
    } = useSelector(({ searchCategory, setting }) => ({ 
        ...pick(searchCategory, [
            'isLoading', 'errors',
            'subCategories', 'categories', 'prices', 'sizes', 
            'selectedSubCategories', 'selectedCategories', 'selectedPrices', 'selectedSizes', 
        ]), 
        service_product_type: setting.values.service_product_type 
    }));

    const showSizes = (selectedPrices.length < 1 && selectedSubCategories.length < 1),
        showSubCategories = (selectedCategories.length === 1 && !!subCategories[selectedCategories[0]]),
        showPrices = (selectedSizes.length < 1);

    const dispatch = useDispatch(),
        initialLoad = () => {
            if (isLoading.indexOf('categories') < 0 && categories.length === 0 && !errors.categories)
                return dispatch(loadSearchCategoryData());
        };

    useEffect(initialLoad, [categories.length, isLoading, errors]);

    return (
        <div>
            <Segment vertical>
                <div className="sm-10">
                    <Header>
                        Market {startCase(service_product_type)} Products by Category
                    </Header>
                </div>
            </Segment>

            <Segment vertical loading={isLoading.indexOf('categories') >= 0}>
                <div className="sm-10">
                    <Header as="h3">
                        Category
                    </Header>
                </div>
                <Divider />
                <SearchCategoryComponentLabel
                    selected={selectedCategories}
                    options={categories.map(({category}) => category)}
                    onClick={(category) => dispatch(toggleSearchCategorySelection('categories', category))}
                />
            </Segment>

            {showSubCategories && (
                <Segment vertical>
                    <div className="sm-10">
                        <Header as="h3">
                            Sub Category
                        </Header>
                    </div>
                    <Divider />
                    <SearchCategoryComponentLabel
                        selected={selectedSubCategories}
                        options={subCategories[selectedCategories[0]]}
                        onClick={(sub) => dispatch(toggleSearchCategorySelection('subCategories', sub))}
                    />
                </Segment>
            )}

            {showPrices && (
                <Segment vertical>
                <div className="sm-10">
                    <Header as="h3">
                        Price (Avg Retail)
                    </Header>
                </div>
                <Divider />
                <SearchCategoryComponentLabel
                    options={prices}
                    selected={selectedPrices}
                    onClick={(price) => dispatch(toggleSearchCategorySelection('prices', price))}
                />
                </Segment>
            )}

            {showSizes && (
                <Segment vertical>
                <div className="sm-10">
                    <Header as="h3">
                        Size
                    </Header>
                </div>
                <Divider />
                <SearchCategoryComponentLabel
                    selected={selectedSizes}
                    options={sizes.map(({product_size_group}) => product_size_group)}
                    onClick={(size) => dispatch(toggleSearchCategorySelection('sizes', size))}
                />
                </Segment>
            )}
        </div>
    );
};

export default SearchCategoryContainerMarketslicePicker;