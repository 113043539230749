import {
    SEARCH_ACCOUNT_CHANGE_TYPE,
    SEARCH_ACCOUNT_TOGGLE_INPUT,
    SEARCH_ACCOUNT_CHANGE_QUERY,
    SEARCH_ACCOUNT_SELECT,
    SEARCH_ACCOUNT_SUGGESTIONS_LOADING,
    SEARCH_ACCOUNT_SUGGESTIONS_DATA,
    SEARCH_ACCOUNT_SUGGESTIONS_ERROR,
} from "../constants";
import { generateReducer } from "../../common/helpers";

const accountTypes = ["All", "Chain", "Corporate", "Independent", "Group", "Rep", "Segment"];

const initialState = {
    error: null,
    accountTypes,
    selected: '',
    inputQuery: '',
    suggestions: {},
    isLoading: false,
    showInput: false,
    selectedType: accountTypes[0],
};

const setLoading = (state) => ({ ...state, isLoading: true });
const changeQuery = (state, inputQuery) => ({ ...state, inputQuery });
const toggleInput = (state) => {
    const showInput = !state.showInput,
        newState = { ...state, showInput };

    // if hiding, previous selections, suggestions and input should be cleaned up
    if (!showInput) {
        newState.selected = '';
        newState.inputQuery = '';
        newState.suggestions = {};
    }

    return newState;
};

const changeType = (state, selectedType) => ({ ...state, selectedType, suggestions: {}, selected: '', inputQuery: '' });

const setSuggestionError = (state, error) => ({ ...state, isLoading: false, error });
const selectAccount = (state, selected) => ({ ...state, selected, inputQuery: selected });
const setSuggestions = (state, suggestions) => ({ ...state, suggestions, isLoading: false, error: null });

const reducers = {
    [SEARCH_ACCOUNT_CHANGE_TYPE]: changeType,
    [SEARCH_ACCOUNT_TOGGLE_INPUT]: toggleInput,
    [SEARCH_ACCOUNT_CHANGE_QUERY]: changeQuery,

    [SEARCH_ACCOUNT_SELECT]: selectAccount,
    [SEARCH_ACCOUNT_SUGGESTIONS_LOADING]: setLoading,
    [SEARCH_ACCOUNT_SUGGESTIONS_DATA]: setSuggestions,
    [SEARCH_ACCOUNT_SUGGESTIONS_ERROR]: setSuggestionError,
};

export default generateReducer(reducers, initialState);