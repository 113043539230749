import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Button } from 'semantic-ui-react';

import { PremiseTypes } from '../constants';

const SettingComponentPremise = ({ service_premises, isLoading, onChange}) => {
    return (
        <Grid columns={2} verticalAlign="middle" padded className="compact">
            <Grid.Column>
                Premise
            </Grid.Column>
            <Grid.Column textAlign="right">
                <Button.Group size="tiny">
                    <Button
                        loading={isLoading}
                        content={PremiseTypes.ON}
                        onClick={() => !!onChange && onChange(PremiseTypes.ON)}
                        color={service_premises === PremiseTypes.ON ? 'blue' : 'grey'}
                        />
                    <Button.Or />
                    <Button
                        loading={isLoading}
                        content={PremiseTypes.OFF}
                        onClick={() => !!onChange && onChange(PremiseTypes.OFF)}
                        color={service_premises === PremiseTypes.OFF ? 'blue' : 'grey'}
                    />
                </Button.Group>
            </Grid.Column>
        </Grid>
    );
};

SettingComponentPremise.propTypes = {
    service_premises: PropTypes.oneOf(Object.values(PremiseTypes)).isRequired,
    isLoading: PropTypes.bool.isRequired,
    onChange: PropTypes.func,
};

export default SettingComponentPremise;