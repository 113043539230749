import React from 'react';
import { pick,startCase } from 'lodash';
import { useSelector } from 'react-redux';
import { Header } from 'semantic-ui-react';

const Subheader = () => {
    const { 
        selected,
        selectedType,
    } = useSelector(({ searchProduct }) => ({
        ...pick(searchProduct, [ 'selected', 'selectedType' ]),
    }));
    
    return (
        <Header.Subheader>
            <b>{startCase(selectedType)}</b> Data { selected ? ` limited to ${selected}` : ''}
        </Header.Subheader>
    );
};

export default Subheader;