import { pick, startCase } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Header, Label, Segment, Message, Divider, Input, Icon } from 'semantic-ui-react';

import { loadSearchCategoryCustomCategories, toggleSearchCategorySelection,  } from '../actions';
import { changeSideNavView } from '../../../common/actions';

const SearchCategoryContainerCustomPicker = () => {
    const { 
        errors,
        isLoading,
        customCategories,
    } = useSelector(({ searchCategory }) => pick(searchCategory, [
        'customCategories', 'isLoading', 'errors',
    ]));

    const [searchQuery, setSearchQuery] = useState('');
    const filteredCustomCategories = searchQuery.length > 0 
        ? customCategories.filter(c => c.portfolio_name.toLowerCase().indexOf(searchQuery.toLowerCase()) >= 0) 
        : customCategories;

    const dispatch = useDispatch();

    const initialLoad = () => {
        if (isLoading.indexOf('customCategories') < 0 && customCategories.length === 0 && !errors.customCategories)
            dispatch(loadSearchCategoryCustomCategories());
    };

    const handleSelection = (e, cat) => {
        e.preventDefault();
        dispatch(toggleSearchCategorySelection('customCategory', cat.portfolio_name));
        dispatch(changeSideNavView(null));
    };

    useEffect(initialLoad, [customCategories.length, isLoading, errors]);

    return (
        <div>
            <Segment vertical>
                <div className="sm-10">
                    <Header>
                        Your Custom Categories
                    </Header>
                </div>
            </Segment>

            {!errors.customCategories && customCategories.length > 0 && (
                <Segment vertical>
                    <div className="sp-10">
                        <Input 
                            fluid
                            transparent
                            value={searchQuery} 
                            placeholder='Quick search...' 
                            onChange={(e, {value}) => setSearchQuery(value)}
                            icon={<Icon
                                name={searchQuery.length > 0 ? 'close' : 'search'} 
                                onClick={() => setSearchQuery('')}
                                link={searchQuery.length > 0} 
                            />} 
                        />
                    </div>
                </Segment>
            )}

            <Segment vertical loading={isLoading.indexOf('customCategories') >= 0}>
                <div>
                    {!!errors.customCategories && (
                        <Message 
                            error
                            content={errors.customCategories}
                            header="Sorry, something went wrong"
                        />
                    )}

                    {customCategories.length > 0 && filteredCustomCategories.length < 1 && (
                        <div className="sp-5">
                            Sorry, No matching custom categories found for your search <b>"{ searchQuery }"</b>
                        </div>
                    )}

                    {customCategories.length > 0 && filteredCustomCategories.map((cat) => (
                        <div
                            className="bm-10 sm-10 clickable"
                            onClick={(e) => handleSelection(e, cat)}
                            key={`custom_category_${cat.portfolio_name}_${cat.id}`}
                        >
                            <Label content={`5 items`} color="blue" size="tiny" horizontal /> 
                            <b>{startCase(cat.portfolio_name)}</b>
                            <Divider hidden clearing/>
                        </div>
                    ))}
                    
                    {customCategories.length < 1 && !errors.customCategories && (
                        <div className="sp-5">
                            Sorry, You do not have a custom category yet. Fancy creating one?
                        </div>
                    )}
                </div>
            </Segment>
        </div>
    );
};

export default SearchCategoryContainerCustomPicker;