import { generateReducer } from '../helpers';
import { APP_MODULE_CHANGE_ENV } from '../constants';

const initialState = {
    company: 'production',
    user: 'production',
};

const change = (state, {moduleName, env}) => ({ ...state, [moduleName]: env });

const reducers = {
    [APP_MODULE_CHANGE_ENV]: change,
};

export default generateReducer(reducers, initialState);