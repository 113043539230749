import React from 'react';
import { pick } from 'lodash';
import { useSelector } from 'react-redux';
import { Header, Segment, Divider } from 'semantic-ui-react';

import SearchContainerCategory from '../category/containers';
import SearchAccountCategory from '../account/index.container';
import SearchContainerProduct from '../product/index.container';

const SearchContainerBox = () => {
    const { showBox } = useSelector(({ searchBox }) => pick(searchBox, ['showBox']));

    return (
        <Segment clearing vertical>
            <div className="sm-10">
                <Header as='h3'>
                    Search
                </Header>
            </div>

            {showBox && (
            <>
                <Divider clearing />
                
                <SearchContainerProduct />
                <SearchContainerCategory />
                <SearchAccountCategory/>
            </>
            )}
        </Segment>
    );
};


export default SearchContainerBox;